import React from 'react'
import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'
import Close from '@material-ui/icons/Close'
import { mediaQuery } from 'utils/style'
import CustomModal from './CustomModal'
import { ModalContext } from '.'

const StyledModal = styled(Modal)`
  overflow: auto;
`

const Backdrop = styled.div`
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: -1;
  position: fixed;
`

const Wrapper = styled.div`
  h2 {
    font-size: 50px;
    color: ${({ theme }) => theme.colors.highlight};
    font-weight: bold;
  }
  h3 {
    font-size: 30px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.highlight};
  }
  h4 {
    font-size: 25px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.highlight};
  }
  h5 {
    font-size: 25px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.color};
  }
  h6 {
    font-size: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.color};
  }
  border: 1px solid black;
  background-color: white;
  border-radius: 0;
  height: fit-content;
  display: flex;
  justify-content: center;
  z-index: 1000;
  inset: 0;
  min-height: ${({ isRedesign }) => (isRedesign ? '100%' : 'initial')};
  position: ${({ isRedesign }) => (isRedesign ? 'absolute' : 'unset')};
  padding: ${({ isRedesign }) => (isRedesign ? '16px 80px' : '16px')};
  margin: ${({ isRedesign }) => (isRedesign ? 0 : '40px')};
  @media (max-width: 480px) {
    margin: 0;
  }
`

const Container = styled.div`
  width: 100%;
  position: relative;
  margin: 16px;
  ${mediaQuery(true)}
`

const CloseButton = styled(Close)`
  && {
    font-size: ${({ isRedesign }) => (isRedesign ? '64px' : '32px')};
    right: ${({ isRedesign }) => (isRedesign ? '24px' : '56px')};
    top: ${({ isRedesign }) => (isRedesign ? '24px' : '56px')};
  }
  cursor: pointer;
  position: fixed;
  z-index: 1500;
  ${mediaQuery()({
    media: device => (device === 'mobile' ? 'top: 16px; right: 16px;' : ''),
  })}
`

export default function editableModal({ children, ...props }) {
  const isRedesign = props?.data?.component?.design === 'redesign'
  return (
    <ModalContext.Provider value={props}>
      <StyledModal
        disableEnforceFocus
        BackdropComponent={Backdrop}
        open
        keepMounted
      >
        <Wrapper isRedesign={isRedesign}>
          <Container>
            <CloseButton isRedesign={isRedesign} />
            {children}
            <CustomModal {...props} />
          </Container>
        </Wrapper>
      </StyledModal>
    </ModalContext.Provider>
  )
}
